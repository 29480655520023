import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BABYLON_COLORS } from './Colors';
import './SpaceFactoryCustomize.css';
import circle from './img/circle.svg';
import square from './img/square.svg';
import triangle from './img/triangle.svg';
import { BACKGROUND_COLOR_OPTIONS, COLOR_OPTIONS, encodeSvg, PARTICLE_COLOR_OPTIONS, PARTICLE_SHAPE_OPTIONS, playSound, TOKEN_DATA } from './constants';

const SPACE_FACTORY_INFORMATION_URL = 'https://api.hyaliko.com/space-factory/information';

const COLOR_NAMES = ['diamond', 'steel', 'obsidian', 'emerald', 'lavender quartz', 'amethyst', 'amber', 'ruby', 'garnet', 'topaz', 'sapphire'];
const BACKGROUND_NAMES = ['void', 'forged', 'stranded', 'aboreal', 'stratospheric', 'galactic', 'enlightened', 'blistering', 'submerged'];
const PARTICLE_SHAPE_NAMES = ['ethereal', 'fragmented', 'glitched'];
const PARTICLE_COLOR_NAMES = ['white', 'gray', 'black', 'green', 'purple', 'sky', 'orange', 'red', 'blue'];

const COLORS_TO_COLOR3: { [key: string]: BABYLON.Color3 } = {};
COLOR_OPTIONS.forEach(colorOption => COLORS_TO_COLOR3[colorOption] = BABYLON.Color3.FromHexString(colorOption));

const PARTICLE_COLORS_TO_COLOR4: { [key: string]: BABYLON.Color4 } = {};
PARTICLE_COLOR_OPTIONS.forEach(colorOption => {
    PARTICLE_COLORS_TO_COLOR4[colorOption] = BABYLON.Color3.FromHexString(colorOption).toColor4();
    PARTICLE_COLORS_TO_COLOR4[colorOption].a = 1;
});

const PARTICLE_SHAPE_TO_FUNCTION: { [key: string]: (color: string) => void } = {
    'circle': getCircleSvg,
    'triangle': getTriangleSvg,
    'square': getSquareSvg
};
const PARTICLE_SHAPE_TO_IMAGE: { [key: string]: string } = { circle, triangle, square };

const BACKGROUND_COLORS_TO_COLOR3: { [key: string]: BABYLON.Color3 } = {};
BACKGROUND_COLOR_OPTIONS.forEach(colorOption => BACKGROUND_COLORS_TO_COLOR3[colorOption] = BABYLON.Color3.FromHexString(colorOption));

const BACKGROUND_COLORS_TO_COLOR4: { [key: string]: BABYLON.Color4 } = {};
BACKGROUND_COLOR_OPTIONS.forEach(colorOption => {
    BACKGROUND_COLORS_TO_COLOR4[colorOption] = BABYLON.Color3.FromHexString(colorOption).toColor4(1);
});


const HYALIKO_TOKENS_URL = 'https://api.hyaliko.com/getHyalikoTokens';

// Eventually this data might have to actually live in the smart contract in the token JSON file
// For rendering script

function getCircleSvg(color: string) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" height="150" width="150"><defs><radialGradient id="gradient"><stop stop-opacity="1" stop-color="${color}" offset="0" /><stop stop-opacity="0" stop-color="${color}" offset="0.9" /></radialGradient></defs><circle cx="32" cy="32" r="32" fill="url(#gradient)"></circle></svg>`;
    return encodeSvg(svg);
}

function getSquareSvg(color: string) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" height="150" width="150"><rect width="32" height="32" x="16" y="16" fill="${color}"></rect></svg>`;
    return encodeSvg(svg);
}

function getTriangleSvg(color: string) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" height="150" width="150"><polygon points="16,48 32,16 48,48" fill="${color}"></polygon></svg>`;
    return encodeSvg(svg);
}

function renderSpaces(spaces: any[], selectedSpace: any, setSelectedSpace: (space: number) => void, spaceLoading: boolean, menuTab: number, setMenuTab: (value: number) => void, inventory: number[], perSpaceInventory: number[]) {
    return (
        <div className="sf-tab-body">
            <div className="sf-tab-inner-body">
                <div className="sf-customize-section">
                    {spaces.map((token, i) => {
                        return (
                            <div className={`sf-customize-space-outer ${(selectedSpace && selectedSpace.token_id) === token.token_id ? 'sf-customize-space-outer-selected' : ''}`} key={token.token_id}>
                                <div className={`sf-customize-space ${(selectedSpace && selectedSpace.token_id) === token.token_id ? 'sf-customize-space-selected' : ''}`}>
                                    <button className="sf-customize-space-button" disabled={inventory.includes(i)} onClick={() => !spaceLoading && setSelectedSpace(i)}>
                                        <img src={token.image_url} className="sf-customize-space-image" alt="space" />
                                        {inventory.includes(i) && (
                                            <div className="sf-customize-space-sold-out">sold out</div>
                                        )}
                                    </button>
                                </div>
                                <div className={`sf-customize-space-label sf-customize-space-label-first ${(selectedSpace && selectedSpace.token_id) === token.token_id ? 'sf-customize-space-label-selected' : ''}`}>{token.name.split(': ')[1]}</div>
                                <div className={`sf-customize-space-label sf-customize-space-label-truncate ${(selectedSpace && selectedSpace.token_id) === token.token_id ? 'sf-customize-space-label-selected' : ''}`}>{perSpaceInventory ? `${perSpaceInventory[i]} / 50 minted` : '...'}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="sf-customize-control">
                <button className="big-button menu-button curate-button" disabled={!selectedSpace} onClick={() => setMenuTab(menuTab + 1)}>next</button>
            </div>
        </div>
    );
}

function renderColors(selectedColor: string, setSelectedColor: (color: number) => void, menuTab: number, setMenuTab: (value: number) => void) {
    return (
        <div className="sf-tab-body">
            <div className="sf-tab-inner-body">
                <div className="sf-customize-section">
                    {COLOR_OPTIONS.map((color, i) => {
                        return (
                            <div className={`sf-customize-space ${selectedColor === color ? 'sf-customize-space-selected' : ''}`} key={color}>
                                <button className="sf-customize-space-button sf-customize-space-image" onClick={() => setSelectedColor(i)} style={{ backgroundColor: color }}></button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="sf-customize-control">
                <button className="big-button menu-button curate-button" disabled={!selectedColor} onClick={() => setMenuTab(menuTab + 1)}>next</button>
            </div>
        </div>
    );
}

function renderBackgroundColors(selectedBackgroundColor: string, setSelectedBackgroundColor: (color: number) => void, menuTab: number, setMenuTab: (value: number) => void) {
    return (
        <div className="sf-tab-body">
            <div className="sf-tab-inner-body">
                <div className="sf-customize-section">
                    <div className="sf-customize-section">
                        {BACKGROUND_COLOR_OPTIONS.map((color, i) => {
                            return (
                                <div className={`sf-customize-space ${selectedBackgroundColor === color ? 'sf-customize-space-selected' : ''}`} key={color}>
                                    <button className="sf-customize-space-button sf-customize-space-image" onClick={() => setSelectedBackgroundColor(i)} style={{ backgroundColor: color }}></button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="sf-customize-control">
                <button className="big-button menu-button curate-button" disabled={!selectedBackgroundColor} onClick={() => setMenuTab(menuTab + 1)}>next</button>
            </div>
        </div>
    );
}

function renderParticles(selectedParticleColor: string, setSelectedParticleColor: (color: number) => void, selectedParticleShape: string, setSelectedParticleShape: (shape: number) => void, menuTab: number, setMenuTab: (value: number) => void) {
    return (
        <div className="sf-tab-body">
            <div className="sf-tab-inner-body">
                <div className="sf-customize-section">
                    <div className="sf-customize-section-label">
                        <h2>particle shape</h2>
                    </div>
                    <div className="sf-customize-section">
                        {PARTICLE_SHAPE_OPTIONS.map((shape, i) => {
                            return (
                                <div className={`sf-customize-space ${selectedParticleShape === shape ? 'sf-customize-space-selected' : ''}`} key={shape}>
                                    <button className="sf-customize-space-button sf-customize-space-image" onClick={() => setSelectedParticleShape(i)}><img src={PARTICLE_SHAPE_TO_IMAGE[shape]} className="sf-customize-space-image" alt="space" /></button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="sf-customize-section-label">
                        <h2>particle color</h2>
                    </div>
                    <div className="sf-customize-section">
                        {PARTICLE_COLOR_OPTIONS.map((color, i) => {
                            return (
                                <div className={`sf-customize-space ${selectedParticleColor === color ? 'sf-customize-space-selected' : ''}`} key={color}>
                                    <button className="sf-customize-space-button sf-customize-space-image" onClick={() => setSelectedParticleColor(i)} style={{ backgroundColor: color }}></button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="sf-customize-control">
                <button className="big-button menu-button curate-button" disabled={!(selectedParticleColor && selectedParticleShape)} onClick={() => setMenuTab(menuTab + 1)}>next</button>
            </div>
        </div>
    );
}

// Convert all to string before sending in here
function getNewPath(path: string, hs: string, s: string, t: string, b: string, ps: string, pc: string) {
    s = s === 'NaN' ? null : s;
    t = t === 'NaN' ? null : t;
    b = b === 'NaN' ? null : b;
    ps = ps === 'NaN' ? null : ps;
    pc = pc === 'NaN' ? null : pc;
    const originalHyalikoSpace = hs ? `?hs=${hs}` : '';
    // Validity conditions
    if ((s && t && b && ps && pc) || (s && t && b && ps && !pc) || (s && t && b && !ps && pc) || (s && t && b && !ps && !pc) || (s && t && !b && !ps && !pc) || (s && !t && !b && !ps && !pc) || (!s && !t && !b && !ps && !pc)) {
        const space = s ? `${hs ? '&' : '?'}s=${s}` : '';
        const terrain = t ? `&t=${t}` : '';
        const background = b ? `&b=${b}` : '';
        const particleShape = ps ? `&ps=${ps}` : '';
        const particleColor = pc ? `&pc=${pc}` : '';
        return `${path}${originalHyalikoSpace}${space}${terrain}${background}${particleShape}${particleColor}`;
    } else {
        return `${path}${originalHyalikoSpace}`;
    }
}

function SpaceFactoryCustomize() {
    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const rotationPointRef: React.MutableRefObject<BABYLON.Vector3> = useRef(null);

    const selectedColorRef: React.MutableRefObject<string> = useRef(null);
    const selectedBackgroundColorRef: React.MutableRefObject<string> = useRef(null);
    const selectedParticleColorRef: React.MutableRefObject<string> = useRef(null);

    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const location = useLocation();

    const [menuTab, rawSetMenuTab] = useState(0);
    const setMenuTab = (menuTab: number) => {
        const scrollEls = document.getElementsByClassName('sf-tab-inner-body');
        for (let i = 0; i < scrollEls.length; i++) {
            scrollEls[i].scrollTop = 0;
        }
        rawSetMenuTab(menuTab);
    }

    const selectedSpaceIndex = parseInt(query.get("s"));
    const selectedColorIndex = parseInt(query.get("t"));
    const selectedBackgroundColorIndex = parseInt(query.get("b"));
    const selectedParticleShapeIndex = parseInt(query.get("ps"));
    const selectedParticleColorIndex = parseInt(query.get("pc"));
    const originalHyalikoSpace = query.get('hs');

    const [inventory, setInventory] = useState([]);
    const [spaceFactoryInformation, setSpaceFactoryInformation] = useState(null);

    const [hyalikoSpaces, setHyalikoSpaces] = useState([]);

    // selections
    const setSelectedSpace = (selectedSpaceIndex: number) => {
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    };
    const [spaceLoading, setSpaceLoading] = useState(false);
    const selectedSpace = selectedSpaceIndex !== null ? hyalikoSpaces[selectedSpaceIndex] : null;

    const selectedColor = COLOR_OPTIONS[selectedColorIndex];
    const setSelectedColor = (selectedColorIndex: number) => {
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    };

    const selectedBackgroundColor = BACKGROUND_COLOR_OPTIONS[selectedBackgroundColorIndex];
    const setSelectedBackgroundColor = (selectedBackgroundColorIndex: number) => {
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    };

    const selectedParticleColor = PARTICLE_COLOR_OPTIONS[selectedParticleColorIndex];
    const setSelectedParticleColor = (selectedParticleColorIndex: number) => {
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    };

    const setSelectedParticleShape = (selectedParticleShapeIndex: number) => {
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    }
    const selectedParticleShape = PARTICLE_SHAPE_OPTIONS[selectedParticleShapeIndex];

    useEffect(() => {
        // Check validity on start
        history.replace(getNewPath(location.pathname, originalHyalikoSpace, selectedSpaceIndex.toString(10), selectedColorIndex.toString(10), selectedBackgroundColorIndex.toString(10), selectedParticleShapeIndex.toString(10), selectedParticleColorIndex.toString(10)));
    }, [history, location.pathname, originalHyalikoSpace, selectedBackgroundColorIndex, selectedColorIndex, selectedParticleColorIndex, selectedParticleShapeIndex, selectedSpaceIndex])

    useEffect(() => {
        fetch(`${HYALIKO_TOKENS_URL}?tokenIDs=${Object.keys(TOKEN_DATA).join(',')}`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).catch(() => null)
            .then(res => res ? res.json() : { tokens: [] })
            .then(({ tokens }) => setHyalikoSpaces(tokens.sort((a: any, b: any) => parseInt(a.token_id) - parseInt(b.token_id))));

        fetch('https://api.hyaliko.com/space-factory/inventory').then(res => res.json()).then(json => setInventory(json)).catch(() => { });

        fetch(`${SPACE_FACTORY_INFORMATION_URL}`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).then(res => res.json())
            .then(json => setSpaceFactoryInformation(json))
    }, []);

    // Mount Babylon
    useEffect(() => {
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('customizeRenderCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        sceneRef.current = scene;

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 500, -800), scene);
        camera.attachControl(canvas, true);
        camera.rotation.x = 20 * (Math.PI / 180);
        new BABYLON.PassPostProcess("Scene copy", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON_COLORS.PINK;
        scene.clearColor = BABYLON_COLORS.LIGHT_PINK.toColor4();

        const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        groundMaterial.ambientColor = BABYLON_COLORS.PINK;
        groundMaterial.alpha = 1;
        groundMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;

        // Ambient particle system
        const particles = new BABYLON.ParticleSystem('particles', 2000, scene);
        particles.emitRate *= 1;
        particles.minLifeTime = 10;
        particles.maxLifeTime = 10;
        particles.maxScaleX = 30;
        particles.maxScaleY = 30;
        particles.minScaleX = 30;
        particles.minScaleY = 30;
        particles.minEmitPower = 300;
        particles.maxEmitPower = 320;
        particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', getCircleSvg('#ffffff'), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
        particles.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
        particles.color1 = new BABYLON.Color4(1, 1, 1, 1);
        particles.color2 = new BABYLON.Color4(1, 1, 1, 1);
        particles.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        particles.emitter = new BABYLON.AbstractMesh('emitter', scene);
        const emitter = particles.emitter as BABYLON.AbstractMesh;
        emitter.position = new BABYLON.Vector3(0, -300, 0);
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-6, -3, 0).scale(100), new BABYLON.Vector3(6, 3, 10).scale(100));

        // Abstract mesh to rotate
        const parentMesh = new BABYLON.AbstractMesh('parentMesh');

        scene.registerBeforeRender(() => {
            if (rotationPointRef.current) {
                parentMesh.rotateAround(rotationPointRef.current, BABYLON.Vector3.UpReadOnly, 0.01);
            }

            if (selectedColorRef.current) {
                BABYLON.Color3.LerpToRef(groundMaterial.ambientColor, COLORS_TO_COLOR3[selectedColorRef.current], 0.01, groundMaterial.ambientColor);
                BABYLON.Color3.LerpToRef(scene.ambientColor, COLORS_TO_COLOR3[selectedColorRef.current], 0.01, scene.ambientColor);
            }

            if (selectedBackgroundColorRef.current) {
                BABYLON.Color4.LerpToRef(scene.clearColor, BACKGROUND_COLORS_TO_COLOR4[selectedBackgroundColorRef.current], 0.05, scene.clearColor);
            }
        });

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });
    }, []);

    useEffect(() => {
        if (selectedSpace && selectedSpace.token_id && selectedSpace.uri) {
            setSpaceLoading(true);
            rotationPointRef.current = null;
            const lastMesh = sceneRef.current.getMeshByName('currentSpace');
            if (lastMesh) {
                lastMesh.dispose();
            }
            const parentMesh = sceneRef.current.getMeshByName('parentMesh');
            parentMesh.position = BABYLON.Vector3.Zero();
            parentMesh.resetLocalMatrix();
            const uri = selectedSpace.uri;
            fetch(uri.substring('https://www.hyaliko.com'.length, uri.length))
                .then(res => res.json())
                .then(async tokenData => {
                    // Start particles if this is the first space we're loading
                    const displayTokenData = TOKEN_DATA[parseInt(selectedSpace.token_id)];
                    const particleEmitter = sceneRef.current.getMeshByName('emitter');
                    const particles = particleEmitter.getConnectedParticleSystems()[0];
                    const museumModel = tokenData.intermediateLevelSegment;
                    const splitMuseumModelURL = museumModel.split('/');
                    const rootURL = splitMuseumModelURL.slice(0, splitMuseumModelURL.length - 1).join('/') + '/';
                    const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(rootURL, splitMuseumModelURL[splitMuseumModelURL.length - 1], sceneRef.current);
                    const meshes = assetContainer.meshes;
                    const currentSpace = new BABYLON.AbstractMesh('currentSpace');
                    const groundMaterial = sceneRef.current.getMaterialByName('groundMaterial');
                    meshes.forEach(mesh => {
                        mesh.material = groundMaterial;
                        mesh.parent = currentSpace;
                        mesh.scaling = new BABYLON.Vector3(tokenData.scale, tokenData.scale, tokenData.scale).scale(displayTokenData.scale);
                        mesh.position.y = displayTokenData.yPosition;
                    });
                    rotationPointRef.current = displayTokenData.rotationPoint.scale(tokenData.scale * displayTokenData.scale);
                    currentSpace.parent = parentMesh;
                    assetContainer.addAllToScene();
                    if (!particles.isStarted()) {
                        particles.start();
                    }
                    setSpaceLoading(false);
                });
        }
    }, [selectedSpace]);

    useEffect(() => {
        const selectedColor = COLOR_OPTIONS[selectedColorIndex];
        selectedColorRef.current = selectedColor;
    }, [selectedColorIndex]);

    useEffect(() => {
        const selectedBackgroundColor = BACKGROUND_COLOR_OPTIONS[selectedBackgroundColorIndex];
        selectedBackgroundColorRef.current = selectedBackgroundColor;
    }, [selectedBackgroundColorIndex]);

    useEffect(() => {
        const selectedParticleColor = PARTICLE_COLOR_OPTIONS[selectedParticleColorIndex];
        selectedParticleColorRef.current = selectedParticleColor;
        if (selectedParticleColor) {
            const particles = sceneRef.current.getMeshByName('emitter').getConnectedParticleSystems()[0];
            particles.particleTexture.dispose();
            particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture' + selectedParticleColor, (selectedParticleShape ? PARTICLE_SHAPE_TO_FUNCTION[selectedParticleShape] : getCircleSvg)(selectedParticleColor), sceneRef.current, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
            particles.particleTexture.hasAlpha = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParticleColor]);

    useEffect(() => {
        if (selectedParticleShape) {
            const particles = sceneRef.current.getMeshByName('emitter').getConnectedParticleSystems()[0];
            particles.particleTexture.dispose();
            particles.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture' + selectedParticleColor, PARTICLE_SHAPE_TO_FUNCTION[selectedParticleShape](selectedParticleColor || '#ffffff'), sceneRef.current, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
            particles.particleTexture.hasAlpha = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParticleShape])

    let menuContent = null;
    switch (menuTab) {
        case 0: {
            menuContent = renderSpaces(hyalikoSpaces, selectedSpace, setSelectedSpace, spaceLoading, menuTab, setMenuTab, inventory, spaceFactoryInformation && spaceFactoryInformation.mintedSpaces);
            break;
        }
        case 1: {
            menuContent = renderColors(selectedColor, setSelectedColor, menuTab, setMenuTab);
            break;
        }
        case 2: {
            menuContent = renderBackgroundColors(selectedBackgroundColor, setSelectedBackgroundColor, menuTab, setMenuTab);
            break;
        }
        case 3: {
            menuContent = renderParticles(selectedParticleColor, setSelectedParticleColor, selectedParticleShape, setSelectedParticleShape, menuTab, setMenuTab);
            break;
        }
        case 4: {
            menuContent = (
                <div className="sf-tab-body">
                    <div className="sf-finish-body">
                        <button className="button big-button" onClick={() => history.push(`/mint-space/finalize?${originalHyalikoSpace ? `hs=${originalHyalikoSpace}&` : ''}s=${selectedSpaceIndex}&t=${selectedColorIndex}&b=${selectedBackgroundColorIndex}&ps=${selectedParticleShapeIndex}&pc=${selectedParticleColorIndex}`)}>continue</button>
                    </div>
                </div>
            );
            break;
        }
        default: {
            menuContent = null;
            break;
        }
    }

    const spaceName = selectedSpace ? selectedSpace.name.split(': ')[1] : '';
    const terrainName = selectedColor ? COLOR_NAMES[selectedColorIndex] : '';
    const backgroundName = selectedBackgroundColor ? ` ${BACKGROUND_NAMES[selectedBackgroundColorIndex]}` : '';
    const particleShapeName = selectedParticleShape ? PARTICLE_SHAPE_NAMES[selectedParticleShapeIndex] : '';
    const particleColorName = selectedParticleColor ? `${PARTICLE_COLOR_NAMES[selectedParticleColorIndex]} ` : '';
    const particleName = (selectedParticleColor || selectedParticleShape) ? ` (${particleColorName}${particleShapeName})` : '';
    const generatedName = `${spaceName}${terrainName ? `: ${terrainName}` : ''}${backgroundName}${particleName}`;

    return (
        <div className="container space-factory-customize-container" onClick={playSound}>
            <div className="space-factory-customize-3d">
                <canvas id="customizeRenderCanvas" className="space-factory-customize-canvas"></canvas>
                {!selectedSpace ? <h2 className="generated-space-name">select a space to get started</h2> : <h2 className="generated-space-name" style={{ color: selectedBackgroundColorIndex === 0 ? '#000000' : '#ffffff' }}>{generatedName}</h2>}
            </div>
            <div className="space-factory-customize-menu">
                <div className="menu-header">
                    <button className={`menu-tab sf-menu-tab ${menuTab === 0 ? 'active-menu-tab' : ''}`} onClick={() => setMenuTab(0)}>space</button>
                    <button className={`menu-tab sf-menu-tab ${menuTab === 1 ? 'active-menu-tab' : ''}`} disabled={menuTab < 1 && !selectedSpace} onClick={() => setMenuTab(1)}>terrain</button>
                    <button className={`menu-tab sf-menu-tab ${menuTab === 2 ? 'active-menu-tab' : ''}`} disabled={menuTab < 2 && !selectedColor} onClick={() => setMenuTab(2)}>background</button>
                    <button className={`menu-tab sf-menu-tab ${menuTab === 3 ? 'active-menu-tab' : ''}`} disabled={menuTab < 3 && !selectedBackgroundColor} onClick={() => setMenuTab(3)}>particle</button>
                    <button className={`menu-tab sf-menu-tab ${menuTab === 4 ? 'active-menu-tab' : ''}`} disabled={menuTab < 4 && !(selectedParticleColor && selectedParticleShape)} onClick={() => setMenuTab(4)}>finish</button>
                </div>
                {menuContent}
            </div>
        </div>
    );
}

export default SpaceFactoryCustomize;
