import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { BACKGROUND_COLOR_OPTIONS, COLOR_OPTIONS, PARTICLE_COLOR_OPTIONS, PARTICLE_SHAPE_FUNCTIONS, playSound, SPACE_FACTORY_ABI, SPACE_FACTORY_ADDRESS, TOKEN_DATA } from './constants';
import './SpaceFactoryFinalize.css';
import { Web3AddressContext, Web3ModalContext } from './Web3Context';

const ERROR_MAP = [
    'This can only be done after the project has been published.',
    'Variants can only be created with valid hyaliko spaces. Range is 0 - 59.',
    'You don\'t own the genesis hyaliko space you\'re tryinig to redeem.',
    'Variant parameters are limited to a specified preset range (0 - 10 for terrain, 0 - 8 for background, 0 - 2 for particle shape, 0 - 8 for particle color).',
    'All 50 variants of this hyaliko space have been minted.',
    'A variant with this set of parameters already exists.',
    'This hyaliko space is invalid or does not exist yet.',
    'Variants cost 0.1 ETH.'
];

const COLOR_NAMES = ['diamond', 'steel', 'obsidian', 'emerald', 'lavender quartz', 'amethyst', 'amber', 'ruby', 'garnet', 'topaz', 'sapphire'];
const BACKGROUND_NAMES = ['void', 'forged', 'stranded', 'aboreal', 'stratospheric', 'galactic', 'enlightened', 'blistering', 'submerged'];
const PARTICLE_SHAPE_NAMES = ['ethereal', 'fragmented', 'glitched'];
const PARTICLE_COLOR_NAMES = ['white', 'gray', 'black', 'green', 'purple', 'sky', 'orange', 'red', 'blue'];

function mintVariant(web3Address: string, contract: any, space: number, terrain: number, background: number, particleShape: number, particleColor: number) {
    const weiValue = Web3.utils.toWei('0.1');
    return contract.methods.mintVariant(space, terrain, background, particleShape, particleColor).send({
        from: web3Address,
        value: weiValue,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
    });
}

function mintVariantWithHyalikoSpace(web3Address: string, contract: any, originalHyalikoSpace: number, space: number, terrain: number, background: number, particleShape: number, particleColor: number) {
    return contract.methods.mintVariantWithHyalikoSpace(originalHyalikoSpace, space, terrain, background, particleShape, particleColor).send({
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        from: web3Address

    });
}

function SpaceFactoryFinalize() {
    const history = useHistory();

    // Web3
    const { connectWeb3, web3 } = useContext(Web3ModalContext);
    const { web3Address } = useContext(Web3AddressContext);

    const [contract, setContract] = useState(null);
    const [error, setError] = useState(null);
    const [spaceName, setSpaceName] = useState(null);
    const [awaitingApproval, setAwaitingApproval] = useState(false);
    const [awaitingTransaction, setAwaitingTransaction] = useState(false);
    const awaitingTransactionRef = useRef(false);
    const [success, setSuccess] = useState(false);
    const [newSpaceTokenId, setNewSpaceTokenId] = useState(null);

    // Refs
    const sceneRef: React.MutableRefObject<BABYLON.Scene> = useRef(null);
    const rotationPointRef: React.MutableRefObject<BABYLON.Vector3> = useRef(null);
    const query = useRef(new URLSearchParams(useLocation().search));
    const space = parseInt(query.current.get("s"));
    let tokenId: number;
    if (space <= 4) {
        tokenId = space + 5;
    } else {
        tokenId = 15 + (((space - 5) * 10) + 1);
    }
    const terrain = parseInt(query.current.get("t"));
    const background = parseInt(query.current.get("b"));
    const particleShape = parseInt(query.current.get("ps"));
    const particleColor = parseInt(query.current.get("pc"));
    const originalHyalikoSpace = parseInt(query.current.get("hs"));
    const hasOriginalHyalikoSpace = !isNaN(originalHyalikoSpace);

    // Mount Babylon
    useEffect(() => {
        if (!(query.current.get("t") && query.current.get("b") && query.current.get("ps") && query.current.get("pc"))) {
            history.replace('/mint-space');
            return;
        }
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('spaceFinalizeCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();
        sceneRef.current = scene;

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 500, -800), scene);
        camera.attachControl(canvas, true);
        camera.rotation.x = 20 * (Math.PI / 180);
        new BABYLON.PassPostProcess("Scene copy", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON.Color3.FromHexString(COLOR_OPTIONS[terrain]);
        scene.clearColor = BABYLON.Color3.FromHexString(BACKGROUND_COLOR_OPTIONS[background]).toColor4(1);

        const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        groundMaterial.ambientColor = BABYLON.Color3.FromHexString(COLOR_OPTIONS[terrain]);
        groundMaterial.alpha = 1;
        groundMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;

        // Ambient particle system
        const instantiateParticles = (p: BABYLON.ParticleSystem) => {
            p.minLifeTime = 10;
            p.maxLifeTime = 10;
            p.maxScaleX = 30;
            p.maxScaleY = 30;
            p.minScaleX = 30;
            p.minScaleY = 30;
            p.minEmitPower = 300;
            p.maxEmitPower = 320;
            p.particleTexture = BABYLON.Texture.LoadFromDataString('particleTexture', PARTICLE_SHAPE_FUNCTIONS[particleShape](PARTICLE_COLOR_OPTIONS[particleColor]), scene, true, true, true, BABYLON.Texture.NEAREST_SAMPLINGMODE);
            p.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;
            p.color1 = new BABYLON.Color4(1, 1, 1, 1);
            p.color2 = new BABYLON.Color4(1, 1, 1, 1);
            p.colorDead = new BABYLON.Color4(1, 1, 1, 0);
        };
        const particles = new BABYLON.ParticleSystem('particles', 2000, scene);
        const fastParticles = new BABYLON.ParticleSystem('particles', 20000, scene);
        instantiateParticles(particles);
        instantiateParticles(fastParticles);
        fastParticles.emitRate = 1000;
        particles.emitter = new BABYLON.AbstractMesh('emitter', scene);
        fastParticles.emitter = particles.emitter;
        const emitter = particles.emitter as BABYLON.AbstractMesh;
        emitter.position = new BABYLON.Vector3(0, -300, 0);
        particles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-6, -3, 0).scale(100), new BABYLON.Vector3(6, 3, 10).scale(100));
        fastParticles.createBoxEmitter(BABYLON.Vector3.UpReadOnly, BABYLON.Vector3.UpReadOnly, new BABYLON.Vector3(-6, -3, 0).scale(100), new BABYLON.Vector3(6, 3, 10).scale(100));

        // Abstract mesh to rotate
        const parentMesh = new BABYLON.AbstractMesh('parentMesh');

        scene.registerBeforeRender(() => {
            if (rotationPointRef.current) {
                parentMesh.rotateAround(rotationPointRef.current, BABYLON.Vector3.UpReadOnly, awaitingTransactionRef.current ? 0.02 : 0.01);
            }

            if (awaitingTransactionRef.current) {
                fastParticles.start();
            } else {
                fastParticles.stop();
            }
        });

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });

        fetch(`/tokens/${tokenId}`)
            .then(res => res.json())
            .then(async tokenData => {
                setSpaceName(tokenData.name);
                // Start particles if this is the first space we're loading
                const particleEmitter = sceneRef.current.getMeshByName('emitter');
                const particles = particleEmitter.getConnectedParticleSystems()[0];
                const museumModel = tokenData.intermediateLevelSegment;
                const splitMuseumModelURL = museumModel.split('/');
                const rootURL = splitMuseumModelURL.slice(0, splitMuseumModelURL.length - 1).join('/') + '/';
                const assetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(rootURL, splitMuseumModelURL[splitMuseumModelURL.length - 1], sceneRef.current);
                const meshes = assetContainer.meshes;
                const currentSpace = new BABYLON.AbstractMesh('currentSpace');
                const groundMaterial = sceneRef.current.getMaterialByName('groundMaterial');
                const displayTokenData = TOKEN_DATA[tokenId];
                meshes.forEach(mesh => {
                    mesh.material = groundMaterial;
                    mesh.parent = currentSpace;
                    mesh.scaling = new BABYLON.Vector3(tokenData.scale, tokenData.scale, tokenData.scale).scale(displayTokenData.scale);
                    mesh.position.y = displayTokenData.yPosition;
                });
                rotationPointRef.current = displayTokenData.rotationPoint.scale(tokenData.scale * displayTokenData.scale);
                currentSpace.parent = parentMesh;
                assetContainer.addAllToScene();
                if (!particles.isStarted()) {
                    particles.start();
                }
            });
    }, [space, terrain, background, particleShape, particleColor, tokenId, history]);

    useEffect(() => {
        if (web3) {
            setContract(new web3.eth.Contract(SPACE_FACTORY_ABI, SPACE_FACTORY_ADDRESS));
        }
    }, [web3, web3Address]);

    useEffect(() => {
        if (web3 && web3Address && contract) {
            if (hasOriginalHyalikoSpace) {
                contract.methods.mintVariantWithHyalikoSpace(originalHyalikoSpace, space, terrain, background, particleShape, particleColor).estimateGas({
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    from: web3Address
                })
                    .catch((e: any) => {
                        console.error(e);
                        setError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                    });
            } else {
                const weiValue = Web3.utils.toWei('0.1');
                contract.methods.mintVariant(space, terrain, background, particleShape, particleColor).estimateGas({
                    from: web3Address,
                    value: weiValue,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                }).catch((e: any) => {
                    console.error(e);
                    setError(parseInt(e.message.substring('execution reverted: b:0'.length, 'execution reverted: b:0'.length + 1)) - 1);
                });
            }
        }
    }, [web3, web3Address, contract, originalHyalikoSpace, hasOriginalHyalikoSpace, space, terrain, background, particleShape, particleColor]);

    const parsedSpaceName = spaceName && spaceName.split(': ')[1];
    const terrainName = COLOR_NAMES[terrain];
    const backgroundName = ` ${BACKGROUND_NAMES[background]}`;
    const particleShapeName = PARTICLE_SHAPE_NAMES[particleShape];
    const particleColorName = `${PARTICLE_COLOR_NAMES[particleColor]} `;
    const particleName = ` (${particleColorName}${particleShapeName})`;
    const generatedName = `${parsedSpaceName}${terrainName ? `: ${terrainName}` : ''}${backgroundName}\n${particleName}`;

    const formattedError = error !== null ? (isNaN(error) ? 'Insufficient funds or other unknown error.' : ERROR_MAP[error]) : null;

    return (
        <div className="container sf-finalize-container" onClick={playSound}>
            <div className="sf-finalize-header">
                <h1 className="sf-finalize-title">{generatedName}</h1>
            </div>
            <canvas id="spaceFinalizeCanvas" className="sf-finalize-canvas"></canvas>
            <div className="sf-finalize-buttons">
                {success ? (
                    <>
                        <h2>minting successful</h2>
                        <h2><a href={`https://opensea.io/assets/${SPACE_FACTORY_ADDRESS}/${newSpaceTokenId}`} target="_blank" rel="noreferrer">view on opensea</a></h2>
                    </>
                ) : (
                    <>
                        {!web3Address || !web3 ? (
                            <button className="button" onClick={connectWeb3}>connect wallet to mint</button>
                        ) : (
                            <>
                                {awaitingApproval ? (
                                    <h2>awaiting transaction approval...</h2>
                                ) : awaitingTransaction ? (
                                    <h2>transaction submitted. awaiting confirmation...</h2>
                                ) : (
                                    <>
                                        {formattedError && <h2 className="sf-finalize-error">{formattedError}</h2>}
                                        <button className="button big-button sf-mint-button" disabled={(error !== null)} onClick={async () => {
                                            setAwaitingApproval(true);
                                            let event;
                                            if (hasOriginalHyalikoSpace) {
                                                event = mintVariantWithHyalikoSpace(web3Address, contract, originalHyalikoSpace, space, terrain, background, particleShape, particleColor)
                                            } else {
                                                event = mintVariant(web3Address, contract, space, terrain, background, particleShape, particleColor);
                                            }
                                            event.on('transactionHash', () => {
                                                // Transaction submitted. We're now awaiting approval.
                                                setAwaitingApproval(false)
                                                setAwaitingTransaction(true);
                                                awaitingTransactionRef.current = true;
                                            });
                                            event.then(async () => {
                                                // Transaction has been confirmed.
                                                setAwaitingTransaction(false);
                                                setAwaitingApproval(false);
                                                awaitingTransactionRef.current = false;
                                                setSuccess(true);

                                                // get the token ID (this could be wrong but hopefully not)
                                                const balance = await contract.methods.balanceOf(web3Address).call();
                                                const tokenId = await contract.methods.tokenOfOwnerByIndex(web3Address, balance - 1).call();
                                                // Kick-off image generation
                                                fetch(`https://api.hyaliko.com/space-factory/generate-image/${tokenId}`).catch(() => { });
                                                setNewSpaceTokenId(tokenId);
                                            }).catch((e: any) => {
                                                // Something went wrong. User rejected or something worse.
                                                console.error(e);
                                                setAwaitingApproval(false);
                                                setAwaitingTransaction(false);
                                                awaitingTransactionRef.current = false;
                                            });
                                        }}>{hasOriginalHyalikoSpace ? 'mint for free' : 'mint for 0.1 eth'}</button>
                                        {hasOriginalHyalikoSpace && (<h2 style={{ marginTop: 16 }}>redeeming original hyaliko space {originalHyalikoSpace}</h2>)}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default SpaceFactoryFinalize;
