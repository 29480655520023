import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playSound, SPACE_FACTORY_ABI, SPACE_FACTORY_ADDRESS } from './constants';
import './SpaceFactoryIntro.css';
import { Web3AddressContext, Web3ModalContext } from './Web3Context';

const HYALIKO_TOKENS_URL = 'https://api.hyaliko.com/getHyalikoTokens';

function SpaceFactorySelect() {
    const [hyalikoSpaces, setHyalikoSpaces] = useState([]);
    const [selectedHyalikoSpace, setSelectedHyalikoSpace] = useState(null);
    const [contract, setContract] = useState(null);
    const [alreadyRedeemed, setAlreadyRedeemed] = useState(false);
    // Web3
    const { web3, connectWeb3 } = useContext(Web3ModalContext);
    const { web3Address } = useContext(Web3AddressContext);

    useEffect(() => {
        if (contract && selectedHyalikoSpace !== null) {
            contract.methods.ownerOf(selectedHyalikoSpace).call().then((res: any) => {
                setAlreadyRedeemed(true);
            }).catch(() => setAlreadyRedeemed(false));
        }
    }, [contract, selectedHyalikoSpace])

    useEffect(() => {
        if (web3 && web3Address) {
            setContract(new web3.eth.Contract(SPACE_FACTORY_ABI, SPACE_FACTORY_ADDRESS));
        }
    }, [web3, web3Address]);

    const history = useHistory();

    useEffect(() => {
        fetch(`${HYALIKO_TOKENS_URL}?addresses=${web3 && web3Address}`, {
            mode: 'cors',
            credentials: 'same-origin'
        })
            .then(res => res.json())
            .then(json => setHyalikoSpaces(json.tokens))
    }, [web3, web3Address]);
    return (
        <div className="container space-factory-container" onClick={playSound}>
            <div className="inner-container space-factory-inner-container">
                <div className="space-factory-intro-buttons">
                    {web3Address ? (
                        <>
                            <h2>select the genesis hyaliko space you'd like to redeem</h2>
                            <h2>(you'll still get to keep your genesis space if you redeem it)</h2>
                            <div className="space-factory-footer footer-body" style={{ marginBottom: 16, paddingBottom: 0 }}>
                                {(hyalikoSpaces || []).map((token, i) => {
                                    return (
                                        <div className={`footer-item sf-footer-item ${selectedHyalikoSpace === token.token_id ? 'sf-footer-item-selected' : ''}`} key={token.token_id}>
                                            <button style={{ padding: 0 }} onClick={() => setSelectedHyalikoSpace(token.token_id)}><img src={token.image_url} className="footer-item-image" alt="token" /></button>
                                        </div>

                                    );
                                })}
                            </div>
                            <div style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {selectedHyalikoSpace !== null && <div>{hyalikoSpaces.find(hyalikoSpace => hyalikoSpace.token_id === selectedHyalikoSpace).name}</div>}
                                {selectedHyalikoSpace !== null && alreadyRedeemed && <div style={{ color: '#FE0302' }}>this space has already been redeemed</div>}
                            </div>
                            <button className="button big-button" disabled={(selectedHyalikoSpace === null) || alreadyRedeemed} onClick={() => history.push(`/mint-space/customize?hs=${selectedHyalikoSpace}`)}>continue</button>
                        </>
                    ) : (
                        <button className="button" onClick={() => connectWeb3()}>connect wallet to view your original hyaliko spaces</button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SpaceFactorySelect;
