import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './Gallery.css';
import BabylonContainer from './BabylonContainer';
import { useHistory, useParams } from 'react-router-dom';
import { Web3AddressContext, Web3ModalContext } from './Web3Context';
import { CurateContext } from './GalleryContext';
import { GET_GALLERY_URL, SPACE_NAME_TO_ID } from './constants';

// const IS_MOBILE_APP = window.navigator.userAgent.includes('HyalikoApp');
const IS_MOBILE_APP = false;

const AVATARS_URL = 'https://api.hyaliko.com/getAvatars';

const shareData = {
  title: 'hyaliko',
  text: 'check out my hyaliko space!',
  url: window.location.href
}

type GalleryURLParams = {
  addresses: string
}

function Gallery() {
  const [gameState, setGameState] = useState(0);

  // Intro form
  const [importedAvatarOptions, setImportedAvatarOptions] = useState([]);
  const [importedAvatarOptionsLoading, setImportedAvatarOptionsLoading] = useState(false);
  const [selectedImportedAvatar, setSelectedImportedAvatar] = useState(null);
  const { curate, setCurate } = useContext(CurateContext);
  const [playerName, setPlayerName] = useState(window.localStorage.getItem('displayName') || '');

  const startGame = () => {
    if (address === 'test') {
      if ((document.getElementById('avatar-collection') as HTMLSelectElement).value) {
        setSelectedImportedAvatar({
          type: (document.getElementById('avatar-collection') as HTMLSelectElement).value,
          id: (document.getElementById('avatar-token-id') as HTMLInputElement).value,
          image: null,
          uri: (document.getElementById('avatar-collection') as HTMLSelectElement).value === 'mannys.game' ? "https://mannys-game-viewer.herokuapp.com/api/" + (document.getElementById('avatar-token-id') as HTMLInputElement).value : null
        });
      }
    }
    setGameState(gameState + 1);
    window.localStorage.setItem('displayName', playerName);
    const soundtrackNode = document.getElementById('soundtrack') as HTMLAudioElement;
    if (soundtrackNode) {
      soundtrackNode.loop = true;
      soundtrackNode.play();
    }
  }

  // Header state
  const [ready, setReady] = useState(false);
  const [inspectButtonVisible, setInspectButtonVisible] = useState(false);
  const [moveButtonVisible, setMoveButtonVisible] = useState(false);
  const [moving, setMoving] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [expandButtonVisible, setExpandButtonVisible] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [info, setInfo] = useState(null);
  const description = info && info.description;

  // Gallery customization
  const [gallery, setGallery] = useState(null);
  const [galleryLoaded, setGalleryLoaded] = useState(false);

  const { addresses: addressesString } = useParams() as GalleryURLParams;
  const addresses = addressesString ? addressesString.split(',') : [];
  const address = addresses[0];

  const [resolvedAddress, setResolvedAddress] = useState(null);

  const { connectWeb3 } = useContext(Web3ModalContext);
  const { web3Address } = useContext(Web3AddressContext);

  const [slideCount, setSlideCount] = useState(0);

  const history = useHistory();

  useEffect(() => {
    for (let i = 1; i < 6; i++) {
      setTimeout(() => {
        setSlideCount(i);
      }, 250 * (i - 1));
    }

    // Prevent any sort of selection
    document.body.classList.add('no-select');
    const divs = document.getElementsByTagName('div');
    for (let i = 0; i < divs.length; i++) {
      divs[i].classList.add('no-select');
    }

    return () => {
      // Remove select limits
      document.body.classList.remove('no-select');
      const divs = document.getElementsByTagName('div');
      for (let i = 0; i < divs.length; i++) {
        divs[i].classList.remove('no-select');
      }
    };
  }, []);

  useEffect(() => {
    const isLoadingSpecificLevel = !!SPACE_NAME_TO_ID[addressesString];
    fetch(`${GET_GALLERY_URL}?address=${isLoadingSpecificLevel ? 'hyaliko.eth' : address}`, {
      mode: 'cors',
      credentials: 'include'
    }).then(res => res.json()).then(({ gallery: newGallery }) => {
      // Format gallery
      if (newGallery && newGallery.hiddenIds) {
        newGallery.hiddenIdsMap = {};
        newGallery.hiddenIds.forEach((hiddenId: string) => {
          newGallery.hiddenIdsMap[hiddenId] = true;
        });
      }
      if (newGallery && newGallery.canvases) {
        newGallery.usedCanvasesMap = {};
        Object.keys(newGallery.canvases).forEach((canvasId: any) => {
          const canvas = newGallery.canvases[canvasId];
          newGallery.usedCanvasesMap[`${canvas.originalSpaceId}:${canvas.originalLevelCanvasIndex}`] = canvas;
        })
      }
      setGallery(newGallery || {});
      setGalleryLoaded(true);
      setCurate({ ...curate, collaborators: newGallery.collaborators });
    }).catch(() => {
      setGallery({});
      setGalleryLoaded(true);
    });

    if (address.includes('.')) {
      fetch(`https://api.hyaliko.com/resolveENS?address=${address}`).then(res => res.json()).then(json => {
        setResolvedAddress(json.resolvedAddress.toLowerCase());
      });
    } else {
      setResolvedAddress(address.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (web3Address) {
      setImportedAvatarOptionsLoading(true);
      // Get NFTs of a specific collection
      fetch(`${AVATARS_URL}?addresses=${web3Address}`, {
        mode: 'cors',
        credentials: 'same-origin'
      }).catch(() => null)
        .then(res => res ? res.json() : { tokens: [] })
        .then(json => {
          let tokens = json.tokens;
          const avatars = (tokens || []).map((token: any) => ({
            type: token.collection_name,
            id: token.token_id,
            image: token.image_url,
            uri: token.uri,
            model: token.model
          }));
          setImportedAvatarOptionsLoading(false);
          setImportedAvatarOptions(avatars);
        });
    }
  }, [web3Address]);

  useEffect(() => {
    const descriptionElement = document.getElementById('nft-description');
    if (descriptionElement) {
      setExpandButtonVisible(descriptionElement.scrollHeight > descriptionElement.clientHeight);
    }
  }, [description]);

  const selectedImportedAvatarId = selectedImportedAvatar && selectedImportedAvatar.type + ':' + selectedImportedAvatar.id;

  let contents = null;
  switch (gameState) {
    case 0:
      contents = (
        <div className="intro">
          <div className="intro-form">
            <h2 className="opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>entering hyaliko space</h2>
            <h1 className="intro-title opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>{address}</h1>
            <div className="intro-form-body opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
              <div className="intro-row">
                <span>name your avatar</span>
                <input autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="form-text-input big-text-input" value={playerName} maxLength={15} onChange={e => setPlayerName(e.target.value)} onKeyPress={e => e.key === 'Enter' && startGame()} type="text" placeholder="your name"></input>
              </div>
              <div className="intro-row">
                {web3Address ? (
                  <>
                    <span>select an avatar</span>
                    <div className={`avatar-select ${importedAvatarOptions.length === 0 || importedAvatarOptionsLoading ? 'avatar-select-empty' : ''}`}>
                      {importedAvatarOptionsLoading ? 'loading...' : importedAvatarOptions.length === 0 ? 'no avatars found!' : importedAvatarOptions.map(avatar => {
                        const currentId = avatar.type + ':' + avatar.id;
                        return (
                          <div className={`avatar-select-item ${selectedImportedAvatar && currentId === selectedImportedAvatarId ? 'selected-avatar' : ''}`} key={currentId}>
                            <img src={avatar.image} onClick={() => setSelectedImportedAvatar(avatar)} className="avatar-select-item-image" alt="avatar" />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  !IS_MOBILE_APP && (<button onClick={async () => connectWeb3()}>import avatar</button>)
                )}
              </div>
            </div>
          </div>
          {address === 'test' && (
            <>
            <div>
              enter avatar inputs
                <select name="collection" id="avatar-collection">
                  <option value="" disabled selected>select collection</option>
                  <option value="Forgotten Runes Wizards Cult">Wizards</option>
                  <option value="mannys.game">Manny</option>
                  <option value="hyaliko particle forge">hyaliko</option>
                </select>
                <input type="text" id="avatar-token-id" placeholder="token id" />
              </div>
            </>
          )}
          <button className="button big-button intro-button opacity-transition" disabled={!playerName || !ready || (gallery === null)} onClick={() => startGame()} style={{ opacity: slideCount >= 4 ? undefined : 0, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>continue</button>
        </div>
      );
      break;
    case 1:
      if (info && ((info.collectionName && info.tokenId) || info.name || info.description)) {
        contents = (
          <div className={`header ${headerVisible ? 'header-visible' : ''} ${descriptionExpanded ? 'header-description-expanded' : ''}`}>
            <div className="top-row">
              {info.collectionName && <a className="name" href={info.link} target="_blank" rel="noreferrer">{info.collectionName}{info.tokenId && `: ${info.tokenId}`}</a>}
              <a className="name" href={info.link} target="_blank" rel="noreferrer">{info.name}</a>
            </div>
            <div className={`description  ${info.description ? 'description-visible' : ''}`} id="nft-description">{info.description}</div>
            <button className={`button expand ${expandButtonVisible ? 'expand-button-visible' : ''}`} onClick={() => setDescriptionExpanded(!descriptionExpanded)}>{descriptionExpanded ? 'collapse' : 'expand'}</button>
          </div>
        );
      }
      break;
    default:
      break;
  }

  return (
    <>
      <div className={`overlay ${menuVisible ? 'overlay-menu-visible' : ''}`}>
        {contents}
      </div>
      <audio id="soundtrack" src="/sounds/hyaliko.mp3"></audio>
      <audio id="sound1" src="/sounds/01.wav"></audio>
      <audio id="sound2" src="/sounds/02.wav"></audio>
      <audio id="sound3" src="/sounds/03.wav"></audio>
      <audio id="sound4" src="/sounds/04.wav"></audio>
      <audio id="sound5" src="/sounds/05.wav"></audio>
      <audio id="sound6" src="/sounds/06.wav"></audio>
      <audio id="sound7" src="/sounds/07.wav"></audio>
      <audio id="sound8" src="/sounds/08.wav"></audio>
      <BabylonContainer gallery={gallery} galleryLoaded={galleryLoaded} resolvedAddress={resolvedAddress} inspectButtonVisible={inspectButtonVisible} moveButtonVisible={moveButtonVisible} moving={moving} headerVisible={headerVisible} menuVisible={menuVisible} setReady={setReady} setInfo={setInfo} setHeaderVisible={setHeaderVisible} setMenuVisible={setMenuVisible} setInspectButtonVisible={setInspectButtonVisible} setMoving={setMoving} setMoveButtonVisible={setMoveButtonVisible} setDescriptionExpanded={setDescriptionExpanded} playerName={playerName} selectedImportedAvatar={selectedImportedAvatar} gameState={gameState} />
      {!headerVisible && !menuVisible && <button className="game-button back-button" onClick={() => history.goBack()} />}
      {isMobile && gameState === 1 && !menuVisible && navigator.share && <button className={`game-button share-button ${isMobile ? 'top-button-joystick' : 'top-button'}`} onClick={() => {
        shareData.url = window.location.href;
        navigator.share(shareData);
      }}></button>}
    </>
  );
}

export default Gallery;
