import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import close from './img/close.svg';
import ios from './img/ios.svg';
import hamburger from './img/hamburger.svg';
import { slide as Menu } from 'react-burger-menu';
import { useMediaQuery } from 'react-responsive';
import './NewHome.css';
import './Home.css';
import { REVERSE_ENS_URL } from './constants';

// For typescript
declare global {
    interface Window { ethereum: any, web3: any, ReactNativeWebView: any }
}

const HOME_PINK = '#ff85d8';
const HOME_BABYLON_PINK = BABYLON.Color3.FromHexString(HOME_PINK);

const HYALIKO_TOKENS_URL = 'https://api.hyaliko.com/getHyalikoTokens';
const SPACE_FACTORY_INFORMATION_URL = 'https://api.hyaliko.com/space-factory/information';

function getRandomRotationSpeed() {
    const randomBool = Math.random() - 0.5 <= 0;
    return (0.01 + (Math.random() * 0.01)) * (randomBool ? -1 : 1)
}

function getRandomSpeed() {
    return Math.random() * 0.05;
}

function NewHome() {
    const [isOpen, setOpen] = useState(false);
    const [address, setAddress] = useState('');
    const [tokens, setTokens] = useState([]);
    const [tokenMetadatas, setTokenMetadatas] = useState([]);
    const initialReverseMap: { [key: string]: string } = {};
    const [reverseAddress, setReverseAddress] = useState(initialReverseMap);
    const [hyalikoCollectionInformation, setHyalikoCollectionInformation] = useState(null);
    const [spaceFactoryInformation, setSpaceFactoryInformation] = useState(null);
    const history = useHistory();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

    useEffect(() => {
        // Remove single page limitations
        document.body.classList.add('not-single-page');
        document.getElementById('root').classList.add('not-single-page');
        document.documentElement.classList.add('not-single-page');


        return () => {
            // Re-add limitations
            document.body.classList.remove('not-single-page');
            document.getElementById('root').classList.remove('not-single-page');
            document.documentElement.classList.remove('not-single-page');
        };
    });

    useEffect(() => {
        fetch(`${HYALIKO_TOKENS_URL}?tokenIDs=0,1,2,3,4,15,25,35,45,55,65,75,85,95,105,115,125,135,145,155,165,175,185,195,205,215,225,235,245,255`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).catch(() => null)
            .then(res => res ? res.json() : { tokens: [] })
            .then((json) => {
                const tokens = json.tokens || [];
                tokens.reverse();
                setTokens(tokens);
                Promise.all(tokens.map(({ uri }: { uri: string }) => uri ? fetch(uri.substring('https://www.hyaliko.com'.length, uri.length)).then(res => res.json()).catch(() => null) : Promise.resolve(null))).then(tokenJson => {
                    setTokenMetadatas(tokenJson);
                });
                Promise.all(tokens.map(({ owner }: { owner: string }) => owner ? fetch(`${REVERSE_ENS_URL}?address=${owner}`).then(res => res.json()).catch(() => null) : Promise.resolve(null))).then(reverseJson => {
                    const reverseMap: { [address: string]: string } = {};
                    reverseJson.forEach(e => {
                        if (e) {
                            const { address, name } = e as { address: string, name: string };
                            reverseMap[address] = name;
                        }
                    });
                    setReverseAddress(reverseMap);
                });
            })


        fetch(`${SPACE_FACTORY_INFORMATION_URL}`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).then(res => res.json())
            .then(json => setSpaceFactoryInformation(json))

        fetch(`https://api.hyaliko.com/hyaliko/information`, {
            mode: 'cors',
            credentials: 'same-origin'
        }).then(res => res.json())
            .then(json => setHyalikoCollectionInformation(json))

        // Initialize babylon animation
        // Get the canvas DOM element
        const canvas: HTMLCanvasElement = document.getElementById('newHomeRenderCanvas') as HTMLCanvasElement;
        // Load the 3D engine
        const engine = new BABYLON.Engine(canvas, false, {
            preserveDrawingBuffer: true, stencil: true, xrCompatible: false
        }, false);

        // Create a basic BJS Scene object
        const scene = new BABYLON.Scene(engine);
        // scene.debugLayer.show();

        const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, 0, 0), scene);
        // camera.attachControl(canvas, true);
        new BABYLON.PassPostProcess("Scene copy", 0.4, camera);

        const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(-1, -1, 0), scene);
        light.intensity = 0.5;
        scene.ambientColor = BABYLON.Color3.White();
        scene.clearColor = HOME_BABYLON_PINK.toColor4();

        const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        groundMaterial.ambientColor = HOME_BABYLON_PINK;
        groundMaterial.alpha = 1;
        groundMaterial.alphaMode = BABYLON.Material.MATERIAL_ALPHABLEND;

        // run the render loop
        engine.runRenderLoop(function () {
            scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            engine.resize();
        });

        BABYLON.SceneLoader.LoadAssetContainerAsync('/models/', 'hyalikobasic.obj', scene).then(assetContainer => {
            const meshes = assetContainer.meshes;
            const mesh = meshes[0] as BABYLON.Mesh;
            mesh.material = groundMaterial;
            mesh.position.z = 10;
            mesh.isVisible = false;
            assetContainer.addAllToScene();
            const instances: { mesh: BABYLON.InstancedMesh, speed: number, rotSpeed: number }[] = [];
            for (let i = 0; i < 100; i++) {
                const instance = mesh.createInstance("i" + i);
                instance.position.x = 100 - (Math.random() * 200);
                instance.position.y = -50 + (Math.random() * 150);
                instance.position.z = (Math.random() * 100);
                instances.push({ mesh: instance, speed: getRandomSpeed(), rotSpeed: getRandomRotationSpeed() });
            }
            scene.registerBeforeRender(() => {
                for (let i = 0; i < 100; i++) {
                    const instance = instances[i];
                    const mesh = instance.mesh;
                    if (mesh.position.y > 100) {
                        mesh.position.y = -50;
                        instance.speed = getRandomSpeed();
                        instance.rotSpeed = getRandomRotationSpeed();
                    } else {
                        mesh.position.y += instance.speed;
                        instance.speed += 0.0001;
                        mesh.rotation.y += instance.rotSpeed;
                        mesh.rotation.z += instance.rotSpeed * 0.5;
                    }
                }
            });
        });
    }, []);

    return (
        <>
            <canvas id="newHomeRenderCanvas"></canvas>
            <div className="new-home-container" id="outer-container">
                <div className="new-home-top-section new-home-top-section-placeholder"></div>
                <div className="new-home-top-section new-home-top-section-video"><video src="/landing-page-video.mp4" autoPlay muted loop playsInline className="new-home-video"></video></div>
                <div className="new-home-top-section">
                    <div className="new-home-header">
                        <div className="new-home-header-left">
                            <a href="#learn" className="new-home-header-link hidden-on-small-screen">learn</a>
                            <a href="#galleries" className="new-home-header-link hidden-on-small-screen">galleries</a>
                            <div className="new-home-mint-container hidden-on-small-screen" style={{ maxWidth: 353 }}>
                                <a href="/mint"><button className="new-home-small-button">mint a hyaliko</button></a>
                                <div className="new-home-subtext new-home-header-mint-info">{hyalikoCollectionInformation === null ? '0' : hyalikoCollectionInformation.totalSupply} hyalikos minted</div>
                            </div>
                        </div>
                        <div className="new-home-header-right">
                            <a href="https://twitter.com/hyaliko" className="new-home-header-link hidden-on-small-screen" target="_blank" rel="noreferrer">twitter</a>
                            <a href="https://discord.gg/2xvCVkAuWu" className="new-home-header-link hidden-on-small-screen" target="_blank" rel="noreferrer">discord</a>
                            <a href="https://apps.apple.com/us/app/hyaliko/id1583505051" className="new-home-header-link hidden-on-small-screen" target="_blank" rel="noreferrer"><img src={ios} style={{ marginTop: 2 }} height={40} alt="apple app store" /></a>
                            <div className="only-on-small-screen">
                                <Menu onStateChange={state => setOpen(state.isOpen)} isOpen={isOpen} right width={'100%'} customBurgerIcon={<img src={hamburger} alt="menu" />} customCrossIcon={<img src={close} alt="close" />}>
                                    <a id="home" className="bm-link" href="#learn" onClick={() => setOpen(false)}>learn</a>
                                    <a id="about" className="bm-link" href="#galleries" onClick={() => setOpen(false)}>galleries</a>
                                    <div className="new-home-mint-container">
                                        <a href="/mint"><button className="new-home-small-button">mint a hyaliko</button></a>
                                        <div className="new-home-subtext new-home-header-mint-info">{hyalikoCollectionInformation === null ? '0' : hyalikoCollectionInformation.totalSupply} hyalikos minted</div>
                                    </div>
                                    <a id="contact" className="bm-link" href="https://twitter.com/hyaliko" target="_blank" rel="noreferrer">twitter</a>
                                    <a id="contact" className="bm-link" href="https://discord.gg/2xvCVkAuWu" target="_blank" rel="noreferrer">discord</a>
                                    <a href="https://apps.apple.com/us/app/hyaliko/id1583505051" className="bm-link" target="_blank" rel="noreferrer"><img src={ios} style={{ marginTop: 2 }} height={40} alt="apple app store" /></a>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <h1 className="new-home-text new-home-title">
                        hyaliko
                    </h1>
                    <div>
                        <a href="/mint"><button className="new-home-button" style={{ width: isTabletOrMobile ? 273 : 353 }}>mint a hyaliko</button></a>
                        <div className="new-home-subtext new-home-header-mint-info">{hyalikoCollectionInformation === null ? '0' : hyalikoCollectionInformation.totalSupply} hyalikos forged</div>
                    </div>
                    <div className="new-home-input-container">
                        <div className="new-home-input-row">
                            <input autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="new-home-input new-home-big-text-input" value={address} maxLength={42} onChange={e => setAddress(e.target.value)} onKeyPress={e => { if (e.key === 'Enter') { window.scrollTo(0, 0); history.push(`/${address}`); } }} type="text" placeholder={isTabletOrMobile ? 'enter any ens or eth address' : 'visit any gallery by entering an eth address (e.g. hyaliko.eth, 0xabcd...)'}></input>
                            <button onClick={() => { window.scrollTo(0, 0); history.push(`/${address}`); }} disabled={!address} className="new-home-continue-button"></button>
                        </div>
                        <div className="new-home-subtext">no wallet connection or login required</div>
                    </div>
                </div>
                <div className="new-home-bottom-section">
                    <div className="new-home-featured-section">
                        <div className="new-home-text new-home-featured-title">visit featured galleries</div>
                        <div className="new-home-featured-row">
                            <div className="new-home-image-container only-on-small-screen" style={{ width: 306, height: 235 }}>
                                <img className="new-home-image" style={{ width: 286, height: 215 }} src="/images/landing-page-image.png" alt="gallery" />
                            </div>
                            {[{ address: 'pet3rpan.eth', gallery: 'astral' }, { address: 'criptoarte.eth', gallery: 'shrine' }, { address: 'sukoneck.eth', gallery: 'astral' }].map(({ address, gallery }) => {
                                return (
                                    <div className="new-featured-gallery" key={address}>
                                        <a href={'/' + address}>
                                            <div className="new-home-image-container new-home-featured-gallery-image-container hidden-on-small-screen">
                                                <div className="new-home-featured-gallery-image-overflow">
                                                    <img className="new-home-image new-home-featured-gallery-image" src={`/images/${gallery}.gif`} alt="gallery" />
                                                </div>
                                            </div>
                                            <button className="new-home-button new-home-featured-gallery-button">{address}</button>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="new-home-learn-section" id="learn">
                        <div className="new-home-learn-row">
                            <div className="new-home-text new-home-learn-text">
                                <h3 className="new-home-learn-title">what is hyaliko?</h3>
                                <p>hyaliko is a universe occupied by the hyaliko particles, purveyors of fine art.</p>
                                <p>any ethereum wallet holder can view their nft gallery in hyaliko by entering their ethereum address (e.g. 0xabcd…) or ens name (e.g. hyaliko.eth).</p>
                                <p>get your gallery link, give it to friends, and hang out while you share your collection.</p>
                            </div>
                            <div className="new-home-learn-image hidden-on-small-screen" style={{ maxWidth: 520, maxHeight: 520 }}>
                                <img src="/images/orb1.gif" className="new-home-learn-orb orb1" alt="orb" />
                                <img src="/images/orb2.gif" className="new-home-learn-orb orb2" alt="orb" />
                                <img src="/images/orb3.gif" className="new-home-learn-orb orb3" alt="orb" />
                            </div>
                        </div>
                        <div className="new-home-learn-row">
                            <div className="new-home-learn-image hidden-on-small-screen">
                                <div className="new-home-image-container" style={{ width: 527, height: 404 }}>
                                    <img className="new-home-image" style={{ width: 492, height: 369, objectFit: 'cover' }} src="/images/hyaliko-avatars.gif" alt="hyaliko avatars" />
                                </div>
                            </div>
                            <div className="new-home-text new-home-learn-text">
                                <h3 className="new-home-learn-title">what is the hyaliko collection?</h3>
                                <p>a hyaliko is an avatar that you can use in your hyaliko space.</p>
                                <p>the hyaliko particle forge is minting now.</p>
                                <div className="new-home-mint-container" style={{ maxWidth: 353, marginTop: isTabletOrMobile ? 22 : 46 }}>
                                    <a href="/mint"><button className="new-home-button" style={{ width: isTabletOrMobile ? 273 : 353 }}>mint a hyaliko</button></a>
                                    <div className="new-home-subtext new-home-header-mint-info">{hyalikoCollectionInformation === null ? '0' : hyalikoCollectionInformation.totalSupply} hyalikos forged</div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <img src="/images/purple-nouns-hyaliko.gif" className="hidden-on-small-screen" style={{ position: 'absolute', top: -120, left: '70%', width: 216, height: 216 }} alt="purple nouns" />
                                    <img src="/images/yellow-hexagon-hyaliko.gif" className="hidden-on-small-screen" style={{ position: 'absolute', top: -30, left: '-5%', width: 216, height: 216 }} alt="yellow hexagon" />
                                    <img src="/images/silver-ethereum-hyaliko.gif" className="hidden-on-small-screen" style={{ position: 'absolute', top: 0, left: '35%', width: 216, height: 216 }} alt="silver ethereum" />
                                </div>
                            </div>
                        </div>
                        <div className="new-home-learn-row">
                            <div className="new-home-text new-home-learn-text">
                                <h3 className="new-home-learn-title">what is a hyaliko space and how do i get one?</h3>
                                <p>owning a hyaliko space transforms the look and layout of your gallery.</p>
                                <p>there are two hyaliko space collections.</p>
                                <p>the genesis hyaliko space collection, <a target="_blank" href="https://opensea.io/collection/hyaliko-space-garden" rel="noreferrer">the hyaliko space garden</a>, is currently available only on the secondary market. more spaces are made available as we create them. the hyaliko space garden collection consists of signature pink archetype hyaliko spaces. maximum supply of 265.</p>
                                <p>the variant hyaliko space collection, <a target="_blank" href="https://opensea.io/collection/hyaliko-space-factory" rel="noreferrer">the hyaliko space factory</a>, allows anyone to customize their own hyaliko space from the existing hyaliko space archetypes. maximum supply of 2500.</p>
                                <p>the hyaliko space factory is minting now.</p>
                                <div className="new-home-mint-container" style={{ maxWidth: 353, marginTop: isTabletOrMobile ? 22 : 46 }}>
                                    <a href="/mint"><button className="new-home-button" style={{ width: isTabletOrMobile ? 273 : 353 }}>mint a hyaliko space</button></a>
                                    <div className="new-home-subtext new-home-header-mint-info">{spaceFactoryInformation === null ? '0' : spaceFactoryInformation.totalSupply} / 2,500 spaces forged</div>
                                </div>
                            </div>
                            <div className="new-home-learn-image hidden-on-small-screen" style={{ justifyContent: 'flex-end' }}>
                                <div className="new-home-learn-image-grid">
                                    {['astral', 'pyrite', 'crystalline', 'city'].map(space => {
                                        return (

                                            <div className="new-home-image-container new-home-space-image-container" key={space}>
                                                <div className="new-home-space-image-overflow">
                                                    <img className="new-home-image new-home-space-image" src={`/images/${space}.gif`} alt="gallery" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="new-home-text new-home-first-edition-section" id="galleries">
                        <h3 className="new-home-text new-home-learn-title">visit galleries from the hyaliko space garden</h3>
                        <p style={{ marginBottom: isTabletOrMobile ? 28 : 80 }}>featured galleries of our first edition holders</p>
                        <div className="new-home-first-edition-galleries">
                            {(tokens || []).map((token, i) => {
                                // If the token metadatas have loaded, try to get the image
                                let tokenImage = null;
                                if (tokenMetadatas.length > 0 && tokenMetadatas[i]) {
                                    tokenImage = tokenMetadatas[i].image;
                                }
                                if (tokenImage) {
                                    const owner = token.owner as string;
                                    const pathArray = tokenImage.replace('-first-edition', '').split('/');
                                    const path = '/' + pathArray[3] + '/' + pathArray[4];
                                    return (
                                        <div className="new-featured-gallery" key={token.token_id}>
                                            <a href={`/${(reverseAddress[owner] || token.owner)}`}>
                                                <div className="new-home-image-container new-home-featured-gallery-image-container">
                                                    <div className="new-home-featured-gallery-image-overflow">
                                                        <img className="new-home-image new-home-featured-gallery-image" src={path} alt="gallery" />
                                                    </div>
                                                </div>
                                                {owner && <button className="new-home-button new-home-featured-gallery-button">{reverseAddress[owner] ? reverseAddress[owner] : owner.slice(0, 9) + '...'}</button>}
                                            </a>
                                        </div>

                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewHome;
