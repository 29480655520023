import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playSound } from './constants';
import './SpaceFactoryIntro.css';

const numLines = 6;

function SpaceFactoryIntro() {
    const history = useHistory();
    const [slideCount, setSlideCount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setSlideCount(1);
        }, 125);
        for (let i = 2; i < numLines; i++) {
            setTimeout(() => {
                setSlideCount(i);
            }, 500 * (i - 1));
        }
    }, []);
    return (
        <div className="container space-factory-container" onClick={playSound}>
            <div className="inner-container space-factory-inner-container">
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 1 ? 1 : 0, transform: slideCount >= 1 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>1. select a space blueprint</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 2 ? 1 : 0, transform: slideCount >= 2 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>2. customize your variant</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 3 ? 1 : 0, transform: slideCount >= 3 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>3. mint your variant (0.1 eth)</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, marginBottom: 4, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>4. visit your hyaliko gallery to see your nfts in your new space</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, marginBottom: 4, fontSize: 12, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>(note: you can see your hyaliko gallery without minting</h2>
                <h2 className="space-factory-subtitle opacity-transition" style={{ opacity: slideCount >= 4 ? 1 : 0, marginBottom: 64, fontSize: 12, transform: slideCount >= 4 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>by entering your ens name or eth address <a href="/" target="_blank">here</a>)</h2>
                <div className="space-factory-intro-buttons opacity-transition" style={{ opacity: slideCount >= 5 ? 1 : 0, transform: slideCount >= 5 ? 'translate(0, 0)' : 'translate(0, -10px)' }}>
                    <button className="button big-button" onClick={() => history.push('/mint-space/customize')}>continue</button>
                    <h2>or</h2>
                    <button className="button" onClick={() => history.push('/mint-space/select-space')}>mint for free with genesis hyaliko space</button>
                </div>
            </div>
        </div>
    );
}

export default SpaceFactoryIntro;
